import { useTheme } from '@mui/material';

const Logo = () => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="24" viewBox="0 0 58 24" fill="none">
      <path
        d="M15.6719 5.79688C15.6719 7.21354 15.2396 8.43229 14.375 9.45312C13.5104 10.4635 12.2604 11.1979 10.625 11.6562V11.75C14.4375 12.2292 16.3438 14.0312 16.3438 17.1562C16.3438 19.2812 15.5 20.9531 13.8125 22.1719C12.1354 23.3906 9.80208 24 6.8125 24C5.58333 24 4.44271 23.9115 3.39062 23.7344C2.34896 23.5573 1.21875 23.2344 0 22.7656V17.6406C1 18.151 2.01562 18.5312 3.04688 18.7812C4.08854 19.0208 5.04688 19.1406 5.92188 19.1406C7.27604 19.1406 8.26042 18.9531 8.875 18.5781C9.5 18.1927 9.8125 17.5938 9.8125 16.7812C9.8125 16.1562 9.65104 15.6771 9.32812 15.3438C9.00521 15 8.48438 14.7448 7.76562 14.5781C7.04688 14.401 6.10938 14.3125 4.95312 14.3125H3.54688V9.67188H4.98438C8.11979 9.67188 9.6875 8.86979 9.6875 7.26562C9.6875 6.66146 9.45312 6.21354 8.98438 5.92188C8.52604 5.63021 7.90625 5.48438 7.125 5.48438C5.66667 5.48438 4.15625 5.97396 2.59375 6.95312L0.03125 2.82812C1.23958 1.99479 2.47396 1.40104 3.73438 1.04688C5.00521 0.692708 6.45833 0.515625 8.09375 0.515625C10.4583 0.515625 12.3125 0.984375 13.6562 1.92188C15 2.85938 15.6719 4.15104 15.6719 5.79688Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M20.67 14.0312C20.67 10.8438 21.1231 8.26562 22.0294 6.29688C22.946 4.31771 24.2898 2.85938 26.0606 1.92188C27.8419 0.984375 30.0346 0.515625 32.6387 0.515625C33.5658 0.515625 34.6492 0.604167 35.8887 0.78125V5.60938C34.8471 5.41146 33.7169 5.3125 32.4981 5.3125C30.4356 5.3125 28.8992 5.76562 27.8888 6.67188C26.8783 7.57812 26.3263 9.05729 26.2325 11.1094H26.42C27.3575 9.33854 28.8627 8.45312 30.9356 8.45312C32.9669 8.45312 34.5398 9.10938 35.6544 10.4219C36.7794 11.724 37.3419 13.5417 37.3419 15.875C37.3419 18.3854 36.6335 20.3698 35.2169 21.8281C33.8002 23.276 31.821 24 29.2794 24C26.5815 24 24.4721 23.125 22.9513 21.375C21.4304 19.625 20.67 17.1771 20.67 14.0312ZM29.1388 19.1094C29.795 19.1094 30.3263 18.8646 30.7325 18.375C31.1492 17.875 31.3575 17.0833 31.3575 16C31.3575 14.1458 30.6388 13.2188 29.2013 13.2188C28.4929 13.2188 27.9148 13.4583 27.4669 13.9375C27.0294 14.4167 26.8106 15 26.8106 15.6875C26.8106 16.6875 27.0242 17.5104 27.4513 18.1562C27.8783 18.7917 28.4408 19.1094 29.1388 19.1094Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M50.6213 8.71875C51.9338 8.71875 53.116 9.02604 54.1681 9.64062C55.2306 10.2448 56.0535 11.1042 56.6369 12.2188C57.2306 13.3333 57.5275 14.6198 57.5275 16.0781C57.5275 18.6302 56.7567 20.5885 55.215 21.9531C53.6838 23.3177 51.3608 24 48.2463 24C45.5796 24 43.4025 23.5885 41.715 22.7656V17.7031C42.6213 18.1198 43.6421 18.4635 44.7775 18.7344C45.9233 19.0052 46.9077 19.1406 47.7306 19.1406C48.8765 19.1406 49.7567 18.901 50.3713 18.4219C50.9858 17.9323 51.2931 17.2135 51.2931 16.2656C51.2931 15.3906 50.9806 14.7083 50.3556 14.2188C49.7306 13.7292 48.7827 13.4844 47.5119 13.4844C46.5431 13.4844 45.4963 13.6667 44.3713 14.0312L42.1056 12.9062L42.965 0.84375H55.9963V5.98438H48.215L47.9338 9C48.7463 8.84375 49.3244 8.76042 49.6681 8.75C50.0119 8.72917 50.3296 8.71875 50.6213 8.71875Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  );
};

export default Logo;
