import { createClient } from '@sanity/client';
import dayjs from 'dayjs';
import imageUrlBuilder from '@sanity/image-url';
import { useQuery } from '@tanstack/react-query';

type Content = 'PromotionalTopContent' | 'PromotionalSideContent' | 'PortableTextContent';

const QUERY_KEY_RECORD: Record<Content, string> = {
  PromotionalTopContent: 'top-data',
  PromotionalSideContent: 'side-data',
  PortableTextContent: 'footer-data',
};

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: dayjs().format('YYYY-MM-DD'),
});

export const getSanityImage = (ref: string) => {
  const builder = imageUrlBuilder(client);
  return builder.image(ref);
};

export const useSanity = <T>(contentType: Content, pathname?: string) => {
  const type = `_type=="${contentType}"`;
  const path = pathname ? `&& path == "${pathname?.split('/')[2]}"` : '';

  return useQuery({
    queryKey: [QUERY_KEY_RECORD[contentType], pathname || ''],
    queryFn: (): Promise<T[]> => client.fetch(`*[${type}${path}]`).then((value) => value),
  });
};
