const GoldFootball = ({ size = 'small' }: { size: 'small' | 'large' }) => {
  const sizes = {
    small: 16,
    large: 80,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={sizes[size]} height={sizes[size]} viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_6426_148278)">
        <path
          d="M13.1662 14.4756C12.9124 14.2819 12.6892 14.0518 12.5039 13.793C12.0111 14.2832 10.6362 14.6884 10.6362 14.6884L10.9422 15.6106C11.74 15.3519 12.4908 14.9687 13.1662 14.4756Z"
          fill="#00260B"
        />
        <path
          d="M15.6982 8.07433C15.6982 8.07433 15.6959 8.0793 15.6928 8.08695H15.7029C15.7029 8.08695 16.0568 8.22815 16.3945 8.6705C16.5512 6.58319 15.8868 4.51668 14.54 2.90234C14.606 3.39118 14.5823 3.88779 14.47 4.3683C14.5497 4.52557 15.6185 6.66959 15.6982 8.07433Z"
          fill="#FECF01"
        />
        <path
          d="M8.68802 0.0071697C7.74002 -0.0331033 6.79247 0.0965347 5.89111 0.389826L6.54256 2.35323C7.15572 2.15387 8.53715 1.98933 8.53715 1.98933C8.53715 1.98933 8.53792 0.828732 8.68802 0.0071697Z"
          fill="#00260B"
        />
        <path
          d="M11.4102 4.20325H11.4176C11.4176 4.20325 13.3623 4.04636 14.4644 4.35938L14.4698 4.36971C14.5821 3.8892 14.6058 3.3926 14.5398 2.90376C13.0857 1.16305 10.951 0.103094 8.68798 0.0078125C8.53788 0.829375 8.53711 1.99074 8.53711 1.99074C9.76419 2.56817 11.2706 4.06282 11.4102 4.20325Z"
          fill="#FEE266"
        />
        <path
          d="M15.7027 8.08984H15.6927C15.6265 8.23678 14.919 9.74445 13.4826 10.4271L13.4757 10.4302L13.4873 10.4409C13.5731 11.8781 12.5039 13.7941 12.5039 13.7941C12.6891 14.0529 12.9123 14.283 13.1662 14.4768C15.0412 13.1088 16.2176 10.9774 16.3944 8.67416C16.0567 8.23104 15.7027 8.08984 15.7027 8.08984Z"
          fill="#FECF01"
        />
        <path
          d="M15.6927 8.08925C15.6957 8.0816 15.6981 8.07663 15.6981 8.07663C15.6184 6.6719 14.5495 4.52788 14.4698 4.36984L14.4644 4.35951C13.3623 4.04649 11.4176 4.20338 11.4176 4.20338H11.4103L11.4219 4.21524C11.57 5.09535 10.9553 8.23658 10.9553 8.23658C11.4358 8.61464 13.3178 10.2876 13.4756 10.4277L13.4826 10.4246C14.9197 9.74386 15.6265 8.23619 15.6927 8.08925Z"
          fill="#00260B"
        />
        <path
          d="M8.47119 15.9992C8.55937 15.6944 8.60122 15.3783 8.59537 15.0614C9.28589 15.1674 10.6364 14.6875 10.6364 14.6875L10.9424 15.6097C10.1449 15.869 9.31064 16.0005 8.47119 15.9992Z"
          fill="#00260B"
        />
        <path
          d="M2.59708 12.3296L2.60907 12.3365L2.60133 12.343C2.60133 12.343 2.40056 12.664 2.39359 13.2181C1.01644 11.6286 0.313086 9.57449 0.430345 7.48438C0.670216 7.91663 0.98696 8.30254 1.36536 8.62354C1.39592 8.79612 1.81951 11.1514 2.59708 12.3296Z"
          fill="#FECF01"
        />
        <path
          d="M3.40869 1.7215C4.14835 1.13358 4.98933 0.68271 5.89109 0.390625L6.54254 2.35404C5.92939 2.55302 4.71817 3.22994 4.71817 3.22994C4.71817 3.22994 4.02184 2.29511 3.40869 1.7215Z"
          fill="#00260B"
        />
        <path
          d="M3.73094 6.69833L3.72397 6.70101C3.72397 6.70101 2.06363 7.71467 1.36421 8.61429V8.62539C0.98581 8.30438 0.66907 7.91848 0.429199 7.48622C0.557632 5.23123 1.64158 3.12624 3.40792 1.72266C4.02108 2.29664 4.7174 3.23109 4.7174 3.23109C4.07717 4.41656 3.75957 6.50356 3.73094 6.69833Z"
          fill="#FEE266"
        />
        <path
          d="M2.60154 12.3424L2.60928 12.3359C2.75047 12.4155 4.22281 13.216 5.78954 12.9229H5.79728C5.79728 12.9321 5.7938 12.9382 5.7938 12.9382C6.58606 14.1467 8.59535 15.0627 8.59535 15.0627C8.6012 15.3797 8.55935 15.6958 8.47117 16.0006C6.1408 15.9983 3.91643 14.9713 2.3938 13.2195C2.40076 12.6635 2.60154 12.3424 2.60154 12.3424Z"
          fill="#FECF01"
        />
        <path
          d="M2.60919 12.3353L2.5972 12.3284C1.81964 11.1502 1.39797 8.79648 1.36548 8.62237V8.61127C2.06567 7.71279 3.72525 6.69799 3.72525 6.69799L3.73221 6.69531C3.73221 6.70488 3.72989 6.71139 3.72989 6.71139C4.13801 7.50769 6.51519 9.67697 6.51519 9.67697C6.35465 10.2628 5.8413 12.7137 5.79836 12.9192H5.79062C4.22273 13.215 2.75039 12.4149 2.60919 12.3353Z"
          fill="#00260B"
        />
        <path
          d="M8.59525 15.0626C9.28577 15.1686 10.6363 14.6887 10.6363 14.6887C10.6363 14.6887 12.0111 14.2835 12.504 13.7933C12.504 13.7933 13.5732 11.8773 13.4873 10.4401L13.4757 10.4294C13.3179 10.2893 11.4359 8.61635 10.9554 8.23828C9.41262 8.5056 7.91679 8.99133 6.51401 9.68051C6.35347 10.2664 5.84013 12.7173 5.79719 12.9228C5.79719 12.9319 5.7937 12.9381 5.7937 12.9381C6.58596 14.1465 8.59525 15.0626 8.59525 15.0626Z"
          fill="#FEE266"
        />
        <path
          d="M6.51406 9.67814C7.91684 8.98895 9.41266 8.50322 10.9555 8.23591C10.9555 8.23591 11.5702 5.09507 11.422 4.21458L11.4104 4.20271C11.2707 4.06266 9.76435 2.56801 8.53727 1.98828C8.53727 1.98828 7.15584 2.15282 6.54269 2.35219C5.92953 2.55155 4.71832 3.22809 4.71832 3.22809C4.07731 4.41432 3.75971 6.50171 3.73108 6.69648C3.73108 6.70605 3.72876 6.71255 3.72876 6.71255C4.13688 7.50886 6.51406 9.67814 6.51406 9.67814Z"
          fill="#FFE98B"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.65"
          d="M16.0076 5.53544C15.6894 4.57485 15.1918 3.68184 14.5407 2.90277C13.0865 1.16207 10.9519 0.102111 8.68886 0.00682916C6.7774 -0.0722 4.90177 0.536852 3.40956 1.72113C1.6436 3.12471 0.559654 5.2297 0.430834 7.48469C0.313574 9.57481 1.01693 11.629 2.39408 13.2184C3.91671 14.9702 6.14108 15.9973 8.47145 15.9996C10.1611 16.0014 11.8068 15.467 13.1666 14.4751C15.0417 13.1071 16.2181 10.9757 16.3949 8.67246C16.4758 7.61177 16.3442 6.5457 16.0076 5.53544Z"
          fill="url(#paint0_radial_6426_148278)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.65"
          d="M0.82666 10.4668C1.16055 11.4783 1.69311 12.4146 2.39378 13.2219C3.91641 14.9737 6.14078 16.0008 8.47115 16.0031C10.1608 16.0049 11.8065 15.4705 13.1663 14.4785C15.0414 13.1106 16.2178 10.9792 16.3946 8.67595C16.4321 8.17873 16.4229 7.67913 16.3671 7.18359C16.3671 7.18359 16.7829 11.2853 10.2572 12.4505C2.82241 13.7756 0.82666 10.4668 0.82666 10.4668Z"
          fill="url(#paint1_radial_6426_148278)"
        />
        <path
          opacity="0.2"
          d="M3.64368 6.33443C2.48701 5.72218 1.78681 4.05304 3.65103 1.97215C5.51525 -0.108733 8.74968 0.671121 8.47231 3.27318C8.19494 5.87524 5.17598 7.14719 3.64368 6.33443Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6426_148278"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.72871 5.72754) scale(11.2043 11.0829)"
        >
          <stop offset="0.2" stopColor="white" />
          <stop offset="0.73" stopColor="#A3927D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6426_148278"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.1984 14.0737) scale(6.93733 6.86217)"
        >
          <stop offset="0.22" stopColor="white" />
          <stop offset="1" stopColor="#A3927D" />
        </radialGradient>
        <clipPath id="clip0_6426_148278">
          <rect width="16" height="16" fill="white" transform="translate(0.417725)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoldFootball;
