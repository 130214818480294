import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SanityTopItem, StyleObj } from '../../@types';
import useBreakpoints from '../../hooks/useBreakpoints';
import { Link } from 'react-router-dom';
import { getSanityImage } from '../../hooks/useSanity';

const styles: StyleObj = {
  container: {
    cursor: 'pointer',
    padding: '15px 35px 10px',
    backgroundColor: '#282828',
    marginBottom: 0,
    flex: '1 0 auto',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    height: '190px',
  },
  imageContainer: { width: 320 },
  upperText: {
    color: 'black',
    position: 'relative',
    backgroundColor: 'info.600',
    padding: '0 4px',
    display: 'initial',
  },
  title: {
    margin: ' 7px 0 10px',
    position: 'relative',
  },
  button: {
    borderRadius: 0.5,
    px: 1,
    py: 0,
    height: 'unset',
    position: 'relative',
  },
  smallTextContainer: { width: '100%', position: 'relative', paddingBottom: 1 },
  smallText: {
    maxWidth: 544,
    marginTop: 1,
    fontSize: '10px',
    color: 'info.400',
    lineHeight: '14px',
  },
};

type CMSCarouselItemProps = {
  item: SanityTopItem;
};

const CMSCarouselItem = ({ item }: CMSCarouselItemProps) => {
  const { isMobile } = useBreakpoints();
  const image = getSanityImage(item.image.asset._ref).url();
  return (
    <Box sx={styles.container} key={item.title}>
      <Link to={item?.callToActionURL ?? "/"} style={{ textDecoration: 'unset', color: 'unset' }}>
        <Box sx={styles.imageContainer}>
          <img
            alt={item.title}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              maxWidth: 375,
              height: '100%',
              backgroundPosition: 'right -55px top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: isMobile ? 'auto 125px' : '',
            }}
            src={image}
          />
        </Box>
        <Box>
          <Typography variant="h5" sx={styles.upperText}>
            {item.upperText}
          </Typography>
        </Box>
        <Typography variant="h3" sx={styles.title}>
          {item.title}
        </Typography>
        <Button variant="outlined" color="info" size="small" sx={styles.button}>
          {item.buttonText}
        </Button>
        <Box sx={styles.smallTextContainer}>
          <Typography sx={styles.smallText}>{item.infoText}</Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default CMSCarouselItem;
