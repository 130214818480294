const BronzeFootball = ({ size = 'small' }: { size: 'small' | 'large' }) => {
  const sizes = {
    small: 16,
    large: 80,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={sizes[size]} height={sizes[size]} viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_6426_147519)">
        <path
          d="M13.1665 14.4756C12.9126 14.2819 12.6894 14.0518 12.5042 13.793C12.0113 14.2832 10.6365 14.6884 10.6365 14.6884L10.9425 15.6106C11.7402 15.3519 12.491 14.9687 13.1665 14.4756Z"
          fill="#485D66"
        />
        <path
          d="M15.6982 8.07433C15.6982 8.07433 15.6959 8.0793 15.6928 8.08695H15.7029C15.7029 8.08695 16.0568 8.22815 16.3945 8.6705C16.5512 6.58319 15.8868 4.51668 14.54 2.90234C14.606 3.39118 14.5823 3.88779 14.47 4.3683C14.5497 4.52557 15.6185 6.66959 15.6982 8.07433Z"
          fill="#FE9E68"
        />
        <path
          d="M8.68826 0.0071697C7.74026 -0.0331033 6.79272 0.0965347 5.89136 0.389826L6.54281 2.35323C7.15596 2.15387 8.53739 1.98933 8.53739 1.98933C8.53739 1.98933 8.53817 0.828732 8.68826 0.0071697Z"
          fill="#485D66"
        />
        <path
          d="M11.4105 4.20325H11.4178C11.4178 4.20325 13.3625 4.04636 14.4646 4.35938L14.47 4.36971C14.5823 3.8892 14.6061 3.3926 14.5401 2.90376C13.0859 1.16305 10.9513 0.103094 8.68822 0.0078125C8.53813 0.829375 8.53735 1.99074 8.53735 1.99074C9.76443 2.56817 11.2708 4.06282 11.4105 4.20325Z"
          fill="#FEC3A1"
        />
        <path
          d="M15.703 8.08984H15.6929C15.6268 8.23678 14.9192 9.74445 13.4829 10.4271L13.4759 10.4302L13.4875 10.4409C13.5734 11.8781 12.5042 13.7941 12.5042 13.7941C12.6894 14.0529 12.9126 14.283 13.1664 14.4768C15.0407 13.108 16.2179 10.9785 16.3947 8.67339C16.057 8.23104 15.703 8.08984 15.703 8.08984Z"
          fill="#FE9E68"
        />
        <path
          d="M15.6929 8.08925C15.696 8.0816 15.6983 8.07663 15.6983 8.07663C15.6186 6.6719 14.5498 4.52788 14.4701 4.36984L14.4647 4.35951C13.3625 4.04649 11.4178 4.20338 11.4178 4.20338H11.4105L11.4221 4.21524C11.5703 5.09535 10.9556 8.23658 10.9556 8.23658C11.436 8.61464 13.318 10.2876 13.4759 10.4277L13.4828 10.4246C14.92 9.74386 15.6267 8.23619 15.6929 8.08925Z"
          fill="#485D66"
        />
        <path
          d="M8.47119 15.9992C8.55937 15.6944 8.60122 15.3783 8.59537 15.0614C9.28589 15.1674 10.6364 14.6875 10.6364 14.6875L10.9424 15.6097C10.1449 15.869 9.31064 16.0005 8.47119 15.9992Z"
          fill="#485D66"
        />
        <path
          d="M2.59732 12.3296L2.60931 12.3365L2.60158 12.343C2.60158 12.343 2.4008 12.664 2.39384 13.2181C1.01668 11.6286 0.31333 9.57449 0.430589 7.48438C0.67046 7.91663 0.987203 8.30254 1.3656 8.62354C1.39616 8.79612 1.81976 11.1514 2.59732 12.3296Z"
          fill="#FE9E68"
        />
        <path
          d="M3.40894 1.7215C4.1486 1.13358 4.98957 0.68271 5.89133 0.390625L6.54278 2.35404C5.92963 2.55302 4.71841 3.22994 4.71841 3.22994C4.71841 3.22994 4.02209 2.29511 3.40894 1.7215Z"
          fill="#485D66"
        />
        <path
          d="M3.73118 6.69833L3.72422 6.70101C3.72422 6.70101 2.06387 7.71467 1.36445 8.61429V8.62539C0.986057 8.30438 0.669314 7.91848 0.429443 7.48622C0.557877 5.23123 1.64182 3.12624 3.40817 1.72266C4.02132 2.29664 4.71764 3.23109 4.71764 3.23109C4.07741 4.41656 3.75981 6.50356 3.73118 6.69833Z"
          fill="#FEC3A1"
        />
        <path
          d="M2.60154 12.3424L2.60927 12.3359C2.75047 12.4155 4.22281 13.216 5.78954 12.9229H5.79728C5.79535 12.9321 5.7938 12.9382 5.7938 12.9382C6.58606 14.1467 8.59535 15.0627 8.59535 15.0627C8.6012 15.3797 8.55934 15.6958 8.47117 16.0006C6.1408 15.9983 3.91643 14.9713 2.3938 13.2195C2.40076 12.6635 2.60154 12.3424 2.60154 12.3424Z"
          fill="#FE9E68"
        />
        <path
          d="M2.60919 12.3353L2.5972 12.3284C1.81964 11.1502 1.39797 8.79648 1.36548 8.62237V8.61127C2.06567 7.71279 3.72525 6.69799 3.72525 6.69799L3.73221 6.69531C3.73221 6.70488 3.72989 6.71139 3.72989 6.71139C4.13801 7.50769 6.51519 9.67697 6.51519 9.67697C6.35465 10.2628 5.8413 12.7137 5.79836 12.9192H5.79062C4.22273 13.215 2.75039 12.4149 2.60919 12.3353Z"
          fill="#485D66"
        />
        <path
          d="M8.59527 15.0626C9.28579 15.1686 10.6363 14.6887 10.6363 14.6887C10.6363 14.6887 12.0111 14.2835 12.504 13.7933C12.504 13.7933 13.5732 11.8773 13.4873 10.4401L13.4757 10.4294C13.3179 10.2893 11.4359 8.61635 10.9554 8.23828C10.1823 8.35873 9.4216 8.54711 8.68231 8.80117C7.9352 9.03031 7.21022 9.32485 6.51596 9.68128C6.35542 10.2671 5.84208 12.718 5.79914 12.9235C5.7972 12.9327 5.79565 12.9388 5.79565 12.9388C6.58598 14.1465 8.59527 15.0626 8.59527 15.0626Z"
          fill="#FEC3A1"
        />
        <path
          d="M6.51404 9.6789C7.2083 9.32247 7.93328 9.02794 8.68039 8.79879C9.41968 8.54473 10.1804 8.35636 10.9535 8.23591C10.9535 8.23591 11.5682 5.09507 11.42 4.21458L11.4084 4.20271C11.2688 4.06266 9.7624 2.56801 8.53532 1.98828C8.53532 1.98828 7.15389 2.15282 6.54073 2.35219C5.92758 2.55155 4.71636 3.22809 4.71636 3.22809C4.07536 4.41432 3.75775 6.50171 3.72913 6.69648C3.72913 6.70605 3.72681 6.71256 3.72681 6.71256C4.13686 7.50963 6.51404 9.6789 6.51404 9.6789Z"
          fill="#FFDAC6"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.65"
          d="M16.0079 5.53544C15.6897 4.57485 15.1921 3.68184 14.5409 2.90277C13.0868 1.16207 10.9522 0.102111 8.68911 0.00682916C6.77764 -0.0722 4.90201 0.536852 3.4098 1.72113C1.64384 3.12471 0.559898 5.2297 0.431078 7.48469C0.313819 9.57481 1.01717 11.629 2.39433 13.2184C3.91696 14.9702 6.14133 15.9973 8.4717 15.9996C10.1613 16.0014 11.807 15.467 13.1669 14.4751C15.0407 13.1071 16.2179 10.9776 16.3947 8.67246C16.4758 7.61179 16.3443 6.54573 16.0079 5.53544Z"
          fill="url(#paint0_radial_6426_147519)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          opacity="0.65"
          d="M0.82666 10.4644C1.16055 11.4759 1.69311 12.4122 2.39378 13.2195C3.91641 14.9713 6.14078 15.9984 8.47115 16.0007C10.1608 16.0025 11.8065 15.4682 13.1663 14.4762C15.0406 13.1066 16.2178 10.9772 16.3946 8.67205C16.4321 8.17482 16.4229 7.67523 16.3671 7.17969C16.3671 7.17969 16.7814 11.281 10.2572 12.4462C2.8224 13.7732 0.82666 10.4644 0.82666 10.4644Z"
          fill="url(#paint1_radial_6426_147519)"
        />
        <path
          opacity="0.2"
          d="M3.64368 6.33443C2.48701 5.72218 1.78681 4.05304 3.65103 1.97215C5.51525 -0.108733 8.74968 0.671121 8.47231 3.27318C8.19494 5.87524 5.17598 7.14719 3.64368 6.33443Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6426_147519"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.72895 5.72754) scale(11.2043 11.0829)"
        >
          <stop offset="0.2" stopColor="white" />
          <stop offset="0.73" stopColor="#A3A3A3" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6426_147519"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.1984 14.0713) scale(6.93733 6.86217)"
        >
          <stop offset="0.22" stopColor="white" />
          <stop offset="1" stopColor="#A3A3A3" />
        </radialGradient>
        <clipPath id="clip0_6426_147519">
          <rect width="16" height="16" fill="white" transform="translate(0.417725)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BronzeFootball;
