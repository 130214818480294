export const MESSAGES = {
  maxSelections: `Maximum number of selections already made`,
  availabilityChanged: 'Availability of your selection(s) changed',
  awaitingConfirmation: `Awaiting confirmation...`,
  placeBetSuccess: `Confirmed.`,
  outcome: 'The price of your selection(s) changed',
  insufficientFunds: 'You have insufficient funds',
  maxBankers: 'Maximum number of selections added as banker',
  noOpenBets: 'There are currently no open bets to display',
  noResolvedBets:
    'There are currently no resolved bets to display\nBets that are resolved will appear here for 24 hours\nView older resolved bets in your',
  accountHistory: 'Account History',
  ticketCopied: 'Ticket successfully copied',
  rejected: ' rejected.',
  bettingSlipRejected: 'Betting slip rejected.',
  requireConfirmation: 'require confirmation.',
  notAllowedToBet: 'Bet cannot be placed while previous ticket is awaiting confirmation.',
} as const;
