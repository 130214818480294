const SilverFootball = ({ size = 'small' }: { size: 'small' | 'large' }) => {
  const sizes = {
    small: 16,
    large: 80,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={sizes[size]} height={sizes[size]} viewBox="0 0 17 16" fill="none">
      <path
        d="M13.1662 14.4755C12.9124 14.2818 12.6892 14.0518 12.504 13.793C12.0116 14.2831 10.6365 14.6883 10.6365 14.6883L10.9424 15.6104C11.7401 15.3517 12.4908 14.9686 13.1662 14.4755Z"
        fill="#505050"
      />
      <path
        d="M15.6978 8.07374C15.6978 8.07374 15.6959 8.07871 15.6924 8.08636H15.7025C15.7025 8.08636 16.0568 8.22755 16.3941 8.66985C16.5506 6.58285 15.8864 4.51667 14.5401 2.90234C14.606 3.39115 14.5821 3.8877 14.4697 4.36814C14.5494 4.52539 15.6181 6.66917 15.6978 8.07374Z"
        fill="#C5C5C5"
      />
      <path
        d="M8.68843 0.00716888C7.74054 -0.0330995 6.7931 0.0965238 5.89185 0.389782L6.54322 2.35297C7.15669 2.15362 8.53758 1.9891 8.53758 1.9891C8.53758 1.9891 8.53835 0.828638 8.68843 0.00716888Z"
        fill="#505050"
      />
      <path
        d="M11.411 4.20431H11.418C11.418 4.20431 13.3624 4.04744 14.4648 4.36042L14.4699 4.37075C14.5824 3.88982 14.6063 3.39274 14.5403 2.90343C13.0859 1.16292 10.9515 0.103083 8.6887 0.0078125C8.53862 0.829282 8.53784 1.99051 8.53784 1.99051C9.76478 2.56941 11.271 4.06389 11.411 4.20431Z"
        fill="#DADADA"
      />
      <path
        d="M15.7024 8.08984H15.6923C15.6262 8.23677 14.9187 9.74426 13.4829 10.4268L13.4756 10.4299L13.4872 10.4406C13.5734 11.8777 12.5039 13.7934 12.5039 13.7934C12.6891 14.0523 12.9123 14.2823 13.1661 14.476C15.0413 13.1082 16.2172 10.977 16.394 8.67409C16.0567 8.23103 15.7024 8.08984 15.7024 8.08984Z"
        fill="#C5C5C5"
      />
      <path
        d="M15.6924 8.08881C15.6958 8.08116 15.6978 8.07618 15.6978 8.07618C15.6181 6.67161 14.5494 4.52783 14.4697 4.36981L14.4646 4.35948C13.3623 4.04651 11.4178 4.20337 11.4178 4.20337H11.4108L11.4224 4.21524C11.5706 5.09525 10.9556 8.23611 10.9556 8.23611C11.436 8.61414 13.3178 10.2869 13.4756 10.427L13.4829 10.4239C14.9199 9.74323 15.6262 8.23573 15.6924 8.08881Z"
        fill="#505050"
      />
      <path
        d="M8.47168 15.9991C8.56003 15.6944 8.60188 15.3783 8.59584 15.0613C9.28629 15.1673 10.6366 14.6875 10.6366 14.6875L10.9426 15.6096C10.1452 15.8689 9.31103 16.0003 8.47168 15.9991Z"
        fill="#505050"
      />
      <path
        d="M2.5984 12.329L2.61039 12.3359L2.60265 12.3424C2.60265 12.3424 2.4019 12.6634 2.39532 13.2174C1.01832 11.6282 0.315051 9.57425 0.432297 7.48438C0.671869 7.91665 0.988466 8.30254 1.36682 8.62341C1.39737 8.79597 1.82092 11.151 2.5984 12.329Z"
        fill="#C5C5C5"
      />
      <path
        d="M3.41016 1.72135C4.14973 1.1335 4.99062 0.682677 5.89227 0.390625L6.54365 2.35381C5.93056 2.55277 4.71948 3.22961 4.71948 3.22961C4.71948 3.22961 4.02324 2.29489 3.41016 1.72135Z"
        fill="#505050"
      />
      <path
        d="M3.73288 6.69777L3.72592 6.70045C3.72592 6.70045 2.06576 7.71399 1.36642 8.61351L1.36835 8.62461C0.990005 8.30373 0.67341 7.91785 0.433838 7.48557C0.56187 5.23083 1.64608 3.12608 3.41222 1.72266C4.02531 2.29658 4.72155 3.23092 4.72155 3.23092C4.07869 4.41625 3.76073 6.50302 3.73288 6.69777Z"
        fill="#DADADA"
      />
      <path
        d="M2.60259 12.3424L2.61033 12.3359C2.75151 12.4155 4.22407 13.2159 5.79024 12.9229H5.79797L5.79488 12.9382C6.58667 14.1465 8.59572 15.0624 8.59572 15.0624C8.60176 15.3794 8.55991 15.6955 8.47156 16.0002C6.14146 15.9979 3.91734 14.971 2.39526 13.2194C2.40184 12.6635 2.60259 12.3424 2.60259 12.3424Z"
        fill="#C5C5C5"
      />
      <path
        d="M2.61123 12.3346L2.59924 12.3278C1.82176 11.1497 1.40015 8.79624 1.36766 8.62215L1.36572 8.61105C2.06584 7.71268 3.72522 6.69799 3.72522 6.69799L3.73219 6.69531C3.73219 6.70488 3.72986 6.71138 3.72986 6.71138C4.13833 7.5076 6.51485 9.67663 6.51485 9.67663C6.35433 10.2624 5.84104 12.713 5.7981 12.9185H5.79037C4.2242 13.2143 2.75164 12.4142 2.61123 12.3346Z"
        fill="#505050"
      />
      <path
        d="M8.59582 15.0618C9.28627 15.1678 10.6366 14.688 10.6366 14.688C10.6366 14.688 12.0117 14.2828 12.5041 13.7927C12.5041 13.7927 13.5736 11.8769 13.4873 10.4398L13.4757 10.4291C13.3179 10.2891 11.4361 8.6163 10.9557 8.23828C10.1831 8.35879 9.42285 8.54714 8.68402 8.8011C7.93633 9.03011 7.21078 9.32462 6.51598 9.68111C6.35585 10.2669 5.84217 12.7175 5.79924 12.923L5.79614 12.9383C6.58677 14.1458 8.59582 15.0618 8.59582 15.0618Z"
        fill="#DADADA"
      />
      <path
        d="M6.51472 9.67803C7.20952 9.32154 7.93507 9.02703 8.68275 8.79802C9.42196 8.54399 10.1826 8.35564 10.9556 8.2352C10.9556 8.2352 11.5706 5.09472 11.4225 4.21432L11.4109 4.20246C11.2709 4.06243 9.76464 2.56794 8.5377 1.98828C8.5377 1.98828 7.15681 2.15281 6.54334 2.35215C5.92987 2.55149 4.71918 3.22795 4.71918 3.22795C4.07863 4.41405 3.76068 6.5012 3.73206 6.69595C3.73206 6.70552 3.72974 6.71202 3.72974 6.71202C4.1382 7.509 6.51472 9.67803 6.51472 9.67803Z"
        fill="#E9E9E9"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.65"
        d="M16.0066 5.53406C15.6883 4.57393 15.1909 3.68131 14.5402 2.90244C13.0858 1.16194 10.9515 0.102099 8.68865 0.00682834C6.77741 -0.0721918 4.90199 0.536796 3.40995 1.72094C1.64458 3.12436 0.56037 5.22911 0.431565 7.48385C0.314319 9.57373 1.01759 11.6276 2.39459 13.2169C3.91666 14.9685 6.14078 15.9955 8.47088 15.9977C10.1603 15.9996 11.8058 15.4653 13.1655 14.4734C15.0407 13.1056 16.2166 10.9744 16.3934 8.67148C16.4746 7.61068 16.3431 6.54447 16.0066 5.53406Z"
        fill="url(#paint0_radial_6426_148239)"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.65"
        d="M0.828369 10.4664C1.16191 11.4779 1.69444 12.4142 2.39531 13.2212C3.91738 14.9728 6.1415 15.9998 8.47161 16.0021C10.1611 16.0039 11.8065 15.4696 13.1662 14.4777C15.0415 13.1099 16.2173 10.9787 16.3941 8.67578C16.4317 8.17862 16.4225 7.67908 16.3666 7.18359C16.3666 7.18359 16.7825 11.2848 10.2579 12.4499C2.82389 13.7749 0.828369 10.4664 0.828369 10.4664Z"
        fill="url(#paint1_radial_6426_148239)"
      />
      <path
        opacity="0.2"
        d="M3.64477 6.3338C2.48823 5.72162 1.78812 4.05266 3.65212 1.97202C5.51613 -0.108632 8.75019 0.671133 8.47324 3.2729C8.19629 5.87467 5.17574 7.14647 3.64477 6.3338Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6426_148239"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.72938 5.72612) scale(11.203 11.0816)"
        >
          <stop offset="0.2" stopColor="white" />
          <stop offset="0.73" stopColor="#A3A3A3" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6426_148239"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.1987 14.0729) scale(6.93655 6.86139)"
        >
          <stop offset="0.22" stopColor="white" />
          <stop offset="1" stopColor="#A3A3A3" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default SilverFootball;
