import { useAuth } from 'neofusion-fe-shared';
import { useCallback, useEffect, useState } from 'react';
import { JackpotPool, JackpotWonMessage } from '../@types';
import useWebsocket from './useWebsocket';

const useWinningJackpot = () => {
  const [winningJackpot, setWinningJackpot] = useState<Omit<JackpotPool, 'isActive'> | null>(null);

  const { userId } = useAuth();

  const jackpotUpdater = (data: JackpotWonMessage) => {
    const messageData = data[userId];

    if (!messageData) {
      return;
    }

    if (messageData.event === 'jackpotWinner') {
      setWinningJackpot(messageData.payload);
    }
  };

  const { joinRoom: joinJackpotRoom } = useWebsocket({
    callback: jackpotUpdater,
  });

  const clearJackpot = useCallback(() => {
    setWinningJackpot(null);
  }, []);

  useEffect(() => {
    if (userId) {
      joinJackpotRoom(userId);
    }
  }, [joinJackpotRoom, userId]);

  return {
    clearJackpot,
    winningJackpot,
  };
};

export default useWinningJackpot;
