import { SanityTopItem } from '../../@types';
import { useSanity } from '../../hooks/useSanity';
import {} from '../../utils/api';
import CMSCarouselItem from '../molecules/CMSCarouselItem';
import Carousel from './Carousel';

const CMSTopContent = () => {
  const { data: cmsContent } = useSanity<SanityTopItem>('PromotionalTopContent');

  return <Carousel cms>{cmsContent?.map((item) => <CMSCarouselItem item={item} key={item.title} />)}</Carousel>;
};

export default CMSTopContent;
