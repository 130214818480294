import React from 'react';
import { PaginatedData, StyleObj, Tournament } from '../../@types';
import { List, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import TopOfferItem from '../atoms/TopOfferItem';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import Heading from '../atoms/Heading';

const styles: StyleObj = {
  topOfferList: {
    width: 1,
    p: 0,
  },
  topOfferListBtn: {
    p: 0,
  },
};

const TopOfferList = () => {
  const { data: topOfferTournaments } = useQuery({
    queryKey: [QUERY_KEYS.topOfferTournaments],
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments/with-events', {
        page: 1,
        limit: 5,
        topOffer: true,
      }),
    select: (data) => data.items,
  });

  return (
    <>
      <Heading>
        <Heading.Title>Top Offer</Heading.Title>
      </Heading>
      <List sx={styles.topOfferList}>
        {topOfferTournaments?.map((tournament) => (
          <ListItemButton
            component={Link}
            to={`${tournament.competition?.sportId}/tournaments/${tournament.id}`}
            key={tournament.id}
            sx={styles.topOfferListBtn}
          >
            <TopOfferItem tournament={tournament} />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

export default TopOfferList;
