import { IconButton, Stack } from '@mui/material';
import { StyleObj } from '../../@types';
import { JACKPOT_ICONS } from '../../constants';
import { useJackpotPools } from '../../queries';
import JackpotInfo from './JackpotInfo';
import { ChevronRight } from '@mui/icons-material';
import useBreakpoints from '../../hooks/useBreakpoints';

const styles: StyleObj = {
  listContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: {
      xs: 'start',
      md: 'center',
    },
    m: 'auto',
    overflowX: {
      xs: 'scroll',
      md: 'unset',
    },
    p: 1,
    gap: 1,
  },
  icon: {
    position: 'absolute',
    right: -19,
    transform: 'translateX(-50%)',
  },
};

const JackpotList = () => {
  const { data: jackpotData } = useJackpotPools();
  const { isMobile } = useBreakpoints();

  return (
    <Stack direction="row" sx={styles.listContainer}>
      {jackpotData?.map((jackpotPool, index) => {
        const IconComponent = JACKPOT_ICONS[index] ? JACKPOT_ICONS[index] : JACKPOT_ICONS[0];
        return <JackpotInfo jackpotPool={jackpotPool} key={jackpotPool.id} icon={<IconComponent size="small" />} />;
      })}
      {isMobile && (
        <IconButton disableRipple color="inherit" sx={styles.icon}>
          <ChevronRight />
        </IconButton>
      )}
    </Stack>
  );
};

export default JackpotList;
