import { ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { has, omit, uniqueId } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { calculateMaxPayout, calculateTotalOddsForNormalBettingSlip, getCombinations } from 'sportsbook-shared-module';
import {
  AuthorizationResponse,
  BankerMenuOptions,
  Bet,
  Betslip,
  BetslipTicketType,
  BettingType,
  StakeErrors,
  StyleObj,
  WalletTypeOptions,
} from '../../@types';
import {
  BANKER_MENU_OPTIONS,
  BETSLIP_WIDTH,
  BETTING_TYPE_HIT_COUNT_MAP,
  CURRENCY,
  QUERY_KEYS,
  WALLET_TYPE_OPTIONS,
} from '../../constants';
import { MESSAGES } from '../../constants/messages';
import { useBetslip } from '../../contexts/BetslipContext';
import { getBettingSlipSnacklbarMessage, mapStakeToCondition } from '../../helpers';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { useBalances, useGlobalTicketConditions } from '../../queries';
import { postData } from '../../utils/api';
import BetslipBetCount from '../atoms/BetslipBetCount';
import BetslipErrorMessage from '../atoms/BetslipErrorMessage';
import BetslipInfoMessage from '../atoms/BetslipInfoMessage';
import BetslipInput from '../atoms/BetslipInput';
import OpenBetslipButton from '../molecules/OpenBetslipButton';
import BetslipMenu from '../molecules/menus/BetslipMenu';
import BetslipPlaceBetButton from './BetslipPlaceBetButton';
import BetslipItem from './BetslipItem';

const styles: StyleObj = {
  drawer: {
    textAlign: 'center',
    '& .MuiPaper-root': {
      width: {
        xs: '100%',
        sm: BETSLIP_WIDTH,
      },
      background: (theme) => theme.palette.neutral[50],
      borderRadius: {
        xs: 0,
        sm: '4px 4px 0 0',
      },
      margin: 'auto',
      mb: {
        xs: 0,
        md: 4,
      },
    },
    '& form': { maxHeight: '95vh' },
  },
  closeButton: {
    borderRadius: 0,
    borderLeft: (theme) => `1px solid ${theme.palette.neutral[100]}`,
    p: 1.5,
  },
  betStack: {
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: 566,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 14,
      borderColor: 'neutral.50',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: 'neutral.300',
    },
  },
  removeAllButton: { minHeight: 0, p: 0, pl: 1.5, ':hover': { background: 'none' } },
  systemIcon: {
    cursor: 'pointer',
    fontSize: 16,
    color: 'neutral.600',
  },
};

type StakeAmountInput = {
  [key in BettingType]: string;
};

const BetslipDrawer = () => {
  const [open, setOpen] = useState(false);
  const [showSystemBetInputs, setShowSystemBetInputs] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<BankerMenuOptions>('normal');
  const [stakeAmounts, setStakeAmounts] = useState<StakeAmountInput>({} as StakeAmountInput);
  const [stakeErrors, setStakeErrors] = useState<StakeErrors>({});
  const [selectedBalance, setSelectedBalance] = useState<WalletTypeOptions>('main');

  const { data: globalTicketConditions } = useGlobalTicketConditions();

  const { data: balancesData } = useBalances();
  const balance = balancesData?.items.find((balance) => balance.type === selectedBalance)?.balance || 0;

  const {
    bets,
    removeAllBets,
    errors,
    isWaysTicket,
    resetBankers,
    infoMessages,
    uniqueEventCount,
    currentBankerCount,
    updateSinglesStakeAmount,
    resetSinglesStakeAmounts,
    setBettingPrevented,
    betslipTicketType,
  } = useBetslip();

  const updateStakes = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      Object.entries(stakeErrors).forEach(([key, { errorType }]) => {
        const updatedStake = mapStakeToCondition(betslipTicketType, errorType, globalTicketConditions);
        if (!updatedStake) return;

        if (has(stakeAmounts, key)) {
          updateStakeAmount(key, updatedStake.toFixed(2).toString());
          return;
        }

        updateSinglesStakeAmount(key, updatedStake.toFixed(2).toString());
      });

      setStakeErrors({} as StakeErrors);
    },
    [betslipTicketType, globalTicketConditions, stakeAmounts, stakeErrors, updateSinglesStakeAmount]
  );

  const handleStakeChange = (
    key: string,
    value: string,
    callback: (key: string, value: string) => void,
    isSingle = false,
    betslipTicketType?: BetslipTicketType,
    isLiveSingle?: boolean
  ) => {
    const isValidValue = (value: string) => {
      return value !== '' && Number(value) !== 0;
    };

    // Tickets with both pre-match and in-play events are automatically sent to verification
    if (!globalTicketConditions || !betslipTicketType || betslipTicketType === 'mix') {
      callback(key, value);
      return;
    }

    const updatedStakeErrors = { ...stakeErrors };

    if (isValidValue(value)) {
      const stakeAmount = Number(value);
      const minStake = globalTicketConditions[betslipTicketType].minTicketStake;
      const maxStake = isSingle
        ? globalTicketConditions[betslipTicketType].maxStakeSingle
        : globalTicketConditions[betslipTicketType].maxTicketStake;

      if (minStake && stakeAmount < minStake) {
        updatedStakeErrors[key] = {
          errorType: isSingle ? (isLiveSingle ? 'minSingleInPlay' : 'minSinglePreMatch') : 'min',
        };
      } else if (maxStake && stakeAmount > maxStake) {
        updatedStakeErrors[key] = {
          errorType: isSingle ? (isLiveSingle ? 'maxSingleInPlay' : 'maxSinglePreMatch') : 'max',
        };
      } else {
        delete updatedStakeErrors[key];
      }
    }

    setStakeErrors(updatedStakeErrors);
    callback(key, value);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const invalidateData = useInvalidateQuery();

  const placeBetMutation = useMutation({
    mutationFn: (newBetslip: Betslip) => postData('betting-slips', newBetslip),
    onMutate: () => {
      enqueueSnackbar(MESSAGES.awaitingConfirmation, {
        variant: 'info',
        persist: true,
        key: 'awaitingConfirmation',
        transitionDuration: 0,
      });
    },
    onSuccess: (data: AuthorizationResponse[]) => {
      closeSnackbar('awaitingConfirmation');
      if (Array.isArray(data)) {
        const statusCounts = {
          pending: 0,
          rejected: 0,
          successful: 0,
        };

        data.forEach((slip) => {
          let status: 'successful' | 'pending' | 'rejected' = 'successful';
          if (slip.acceptStatus === 'rejected') {
            status = 'rejected';
          } else if (slip.acceptStatus === 'pending') {
            status = 'pending';
          }
          statusCounts[status]++;
        });

        if (statusCounts.pending > 0) {
          enqueueSnackbar(getBettingSlipSnacklbarMessage('pending', statusCounts.pending), {
            key: uniqueId('betting-slip'),
            variant: 'warning',
          });
          setBettingPrevented(true);
        }

        if (statusCounts.rejected > 0) {
          enqueueSnackbar(getBettingSlipSnacklbarMessage('rejected', statusCounts.rejected), {
            key: uniqueId('betting-slip'),
            variant: 'warning',
          });
        }

        if (statusCounts.successful > 0) {
          enqueueSnackbar(getBettingSlipSnacklbarMessage('successful', statusCounts.successful), {
            variant: 'success',
          });
        }

        removeAllBets();
        invalidateData([QUERY_KEYS.myBetsCount, QUERY_KEYS.myBets, QUERY_KEYS.balance, QUERY_KEYS.jackpots]);
        setStakeAmounts({} as StakeAmountInput);
      }
    },
    onError: (error) => {
      closeSnackbar('awaitingConfirmation');
      if (error instanceof AxiosError) {
        enqueueSnackbar(
          error.response?.data?.errorMessage?.message || error.response?.data?.errorMessage || 'Internal Server Error',
          { variant: 'error' }
        );
      }
    },
  });

  const updateStakeAmount = (key: string, value: string) => {
    setStakeAmounts((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const betTypes = Object.entries(stakeAmounts)
    .map(([bettingType, stakeAmount]) => ({
      requiredHitCount: BETTING_TYPE_HIT_COUNT_MAP[bettingType as BettingType] - currentBankerCount,
      stakeAmountPerCombination: Number(stakeAmount),
    }))
    .filter((betType) => betType.stakeAmountPerCombination > 0 && betType.requiredHitCount > 0);

  const preparedBets = bets
    .filter((bet) => !bet.disabled)
    .map((bet) => {
      return {
        ...bet,
        singlesStakeAmount: bet.disabled ? 0 : Number(bet.singlesStakeAmount),
      };
    });

  const maxWinning =
    globalTicketConditions?.[betslipTicketType]?.maxWinning ?? globalTicketConditions?.preMatch?.maxWinning ?? Infinity;
  const maxTicketStake =
    globalTicketConditions?.[betslipTicketType]?.maxTicketStake ??
    globalTicketConditions?.preMatch?.maxTicketStake ??
    Infinity;

  const { maxPayout, totalStakeAmount } = calculateMaxPayout(
    {
      bets: preparedBets,
      betTypes,
    },
    maxWinning,
    maxTicketStake
  );

  const drawerOpen = open || bets.length === 0;
  const totalOdds = calculateTotalOddsForNormalBettingSlip(preparedBets);

  const hasEnoughBalance = balance >= totalStakeAmount;
  const isTotalStakeBelowMinimum =
    totalStakeAmount < (globalTicketConditions?.[betslipTicketType]?.minTicketStake ?? 0);

  const isPlaceBetButtonDisabled = !totalStakeAmount || isTotalStakeBelowMinimum || !hasEnoughBalance;

  const closeBetslipDrawer = () => setOpen(false);

  const preparePayload = () => {
    const betsPayload: Bet[] = bets
      .filter((bet) => !bet.disabled)
      .map((bet) => ({
        eventId: bet.eventId,
        outcomeId: bet.outcomeId,
        odds: bet.odds,
        singlesStakeAmount: Number(bet.singlesStakeAmount || 0) || undefined,
        banker: bet.banker,
      }));

    return {
      bets: betsPayload,
      betTypes,
      walletType: selectedBalance,
      totalStakeAmount,
      currency: CURRENCY.code,
    };
  };

  const placeBet = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = preparePayload();
    placeBetMutation.mutate(payload);
  };

  const combinations = getCombinations(preparedBets);
  const lastCombinationIndex = Object.entries(combinations).length - 1;
  const [lastCombinationKey, lastCombinationValue] = Object.entries(combinations).at(lastCombinationIndex) || [];

  useEffect(() => {
    // Leave only single stake amount input on Single betslips
    if (bets.length === 1) {
      setStakeAmounts({} as StakeAmountInput);
    } else {
      //Removes last input when a bet is removed
      const keyToRemove = Object.keys(BETTING_TYPE_HIT_COUNT_MAP)[uniqueEventCount];
      setStakeAmounts((prevState) => omit(prevState, keyToRemove) as StakeAmountInput);
    }
  }, [bets.length, uniqueEventCount]);

  useEffect(() => {
    if (bets.length === 0) {
      setStakeErrors({});
      closeBetslipDrawer();
    }
  }, [bets.length]);

  useEffect(() => {
    // Redirect user back to normal betslip since bankers are redundant with less than 3 selections
    if (bets.length < 3 && selectedMenuItem === 'bankers') {
      setSelectedMenuItem('normal');
    }
  }, [bets.length, selectedMenuItem]);

  useEffect(() => {
    // Bankers are not allowed in ways ticket
    if (isWaysTicket) {
      resetBankers();
      setSelectedMenuItem('normal');
    }
  }, [isWaysTicket, resetBankers]);

  return (
    <>
      <OpenBetslipButton open={drawerOpen} setOpen={setOpen} />
      <Drawer anchor="bottom" elevation={0} open={open} onClose={closeBetslipDrawer} sx={styles.drawer}>
        <form onSubmit={placeBet}>
          {Array.from(infoMessages)?.map((message) => <BetslipInfoMessage key={message} message={message} />)}
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <BetslipBetCount numberOfBets={bets.length} pl={3} />
            <Stack alignItems="end" flexGrow={1}>
              <BetslipMenu
                selectedMenuItem={selectedBalance}
                onChange={(value) => {
                  setSelectedBalance(value as WalletTypeOptions);
                }}
                options={WALLET_TYPE_OPTIONS}
                style={{ marginRight: -12 }}
              />

              <Typography variant="body2" fontWeight={600} color="neutral.600">
                {CURRENCY.symbol}
                {balance.toFixed(2)}
              </Typography>
            </Stack>
            <IconButton sx={styles.closeButton} onClick={closeBetslipDrawer}>
              <ExpandMore />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
            <Button variant="text" disableRipple sx={styles.removeAllButton} onClick={removeAllBets}>
              <Typography variant="body3" color="primary">
                Remove all
              </Typography>
            </Button>
            <BetslipMenu
              selectedMenuItem={selectedMenuItem}
              onChange={(value) => {
                resetSinglesStakeAmounts();
                setStakeAmounts({} as StakeAmountInput);
                resetBankers();
                setSelectedMenuItem(value as BankerMenuOptions);
              }}
              options={BANKER_MENU_OPTIONS}
              disabledOptions={isWaysTicket || bets.length < 3 ? ['bankers'] : []}
            />
          </Stack>
          <Divider />
          <Stack sx={styles.betStack}>
            {bets.map((bet) => (
              <BetslipItem
                key={bet.outcomeId}
                bet={bet}
                selectedMenuItem={selectedMenuItem}
                value={bet.singlesStakeAmount?.toString() || ''}
                onChange={(value) =>
                  handleStakeChange(
                    bet.outcomeId,
                    value,
                    updateSinglesStakeAmount,
                    true,
                    bet.isLive ? 'inPlay' : 'preMatch',
                    bet.isLive
                  )
                }
              />
            ))}
          </Stack>
          <Divider />
          {bets.length > 1 && (
            <Stack direction="row" alignItems="center">
              <Typography variant="body3" color="neutral.600" pl={1.5} pr={0.25} py={1} textAlign="left">
                Multiple/System
              </Typography>
              {uniqueEventCount > 1 && (
                <ExpandMore
                  onClick={() => {
                    setShowSystemBetInputs((prev) => !prev);
                  }}
                  sx={{ ...styles.systemIcon, ...(showSystemBetInputs && { transform: 'rotate(180deg)' }) }}
                />
              )}
            </Stack>
          )}
          {showSystemBetInputs &&
            Object.entries(combinations).map(
              ([key, value]) =>
                key !== lastCombinationKey && (
                  <BetslipInput
                    key={key}
                    bettingType={key}
                    numberOfCombinations={value}
                    value={stakeAmounts[key as BettingType]}
                    onChange={(value) =>
                      handleStakeChange(key, value, updateStakeAmount, key === 'Single', betslipTicketType)
                    }
                  />
                )
            )}
          {bets.length > 1 && (
            <BetslipInput
              bettingType={lastCombinationKey}
              odds={!isWaysTicket && selectedMenuItem === 'normal' ? `(${totalOdds.toFixed(2)})` : ''}
              numberOfCombinations={lastCombinationValue}
              value={stakeAmounts[lastCombinationKey as BettingType]}
              onChange={(value) =>
                handleStakeChange(lastCombinationKey as BettingType, value, updateStakeAmount, false, betslipTicketType)
              }
            />
          )}
          <Divider />

          {balance < totalStakeAmount && <BetslipErrorMessage message={MESSAGES.insufficientFunds} />}
          {errors.map((message, index) => (
            <BetslipErrorMessage key={`betslip-error-message-${index}`} message={message} />
          ))}

          <Typography variant="body3" color="neutral.600" px={1.5} py={1} textAlign="right">
            Max possible winning: {CURRENCY.symbol}
            {maxPayout.toFixed(2)}
          </Typography>
          <BetslipPlaceBetButton
            stakeErrors={stakeErrors}
            totalStakeAmount={totalStakeAmount}
            updateStakes={updateStakes}
            isDisabled={isPlaceBetButtonDisabled}
          />
        </form>
      </Drawer>
    </>
  );
};

export default BetslipDrawer;
